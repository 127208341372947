$(document).ready(function () {
    $('.hamburger').click(function () {
        $(this).toggleClass('is-active');
    });

    $('.choose-day').submit(function (e) {
        e.preventDefault();
        let errors = [];
        $('span.message').html('');

        if (!$('#terms').is(':checked')) {
            errors.push('You must agree to the T&Cs');
            $('span.terms').addClass('error').html('You must agree to the T&Cs');
        }
        if (!$('#gdpr').is(':checked')) {
            errors.push('You must agree to the GDPR stuff');
            $('span.gdpr').addClass('error').html('You must agree to the GDPR stuff');
        }

        if ($('#confirm_email').val() !== $('#email').val()) {
            errors.push('Email addresses must match');
            $('.email-error').addClass('error').html('Email addresses must match');
        }
        if (errors.length === 0) {
            $.post("/wp-content/themes/100-climbs/scripts/form-handler.php", $('.choose-day').serialize())
                .done(function (data) {
                    $(".submit-message").html(data);
                });
        }
    });

    $('.contact').submit(function (e) {
        e.preventDefault();
        $('span.message').html('');
        console.log($('.contact').serialize());
        $.post("/wp-content/themes/100-climbs/scripts/form-handler.php", $('.contact').serialize())
            .done(function (data) {
                $(".submit-message").html(data);
            });
    });

    $('.marker').click(function () {
        let target = $(this).data('target');
        // Scroll to element
        $('html, body').animate({
            scrollTop: $("." + target).offset().top
        }, 1000);
        // Open carousel
        $("." + target).children().children("button").click()
    })

    // function getTimeRemaining(endtime) {
    //     var t = Date.parse(endtime) - Date.parse(new Date());
    //     var seconds = Math.floor((t / 1000) % 60);
    //     var minutes = Math.floor((t / 1000 / 60) % 60);
    //     var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    //     var days = Math.floor(t / (1000 * 60 * 60 * 24));
    //     return {
    //         'total': t,
    //         'days': days,
    //         'hours': hours,
    //         'minutes': minutes,
    //         'seconds': seconds
    //     };
    // }

    // function initializeClock(id, endtime) {
    //     var clock = document.getElementById(id);
    //     var daysSpan = clock.querySelector('.days');
    //     var hoursSpan = clock.querySelector('.hours');
    //     var minutesSpan = clock.querySelector('.minutes');
    //     var secondsSpan = clock.querySelector('.seconds');

    //     function updateClock() {
    //         var t = getTimeRemaining(endtime);

    //         daysSpan.innerHTML = t.days;
    //         hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    //         minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    //         secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    //         if (t.total <= 0) {
    //             clearInterval(timeinterval);
    //         }
    //     }

    //     updateClock();
    //     var timeinterval = setInterval(updateClock, 1000);
    // }

    // var deadline = new Date(2018, 7, 17, 8);
    // initializeClock('clockdiv', deadline);
})